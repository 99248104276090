<template>
  <v-app-bar id="app-bar" :color="$config.color" class="ml-n4" absolute clipped-left app height="50px">
    <v-btn
      class="mr-2 ml-n1"
      color="white"
      text
      fab
      small
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>
      <v-icon v-else>
        mdi-menu
      </v-icon>
    </v-btn>
    <div class="hidden-sm-and-down font-weight-bold mr-12 white--text text-h2">
      <router-link to="/" class="v-breadcrumbs__item white--text">BIECO DCT</router-link>
    </div>
    <!-- <div class="hidden-sm-and-down font-weight-light mr-12 white--text text-h2">{{ $t($route.meta.title) }}</div> -->
    <!-- <v-spacer /> -->
    <div style="min-width: 200px;width:350px">
      <v-text-field
        v-model="search"
        placeholder="e.g.: CVE-2009-1234"
        class="white--text ml-5"
        dark
        hide-details
        style="min-width: 150px"
        @keydown.enter="searchGeneral"
      >
        <template v-if="$vuetify.breakpoint.mdAndUp" #append-outer>
          <!-- <v-btn class="mt-0" color="white" fab text icon x-small> -->
          <!-- <v-icon v-show="search" class="mt-1" @click="search = ''">mdi-close</v-icon> -->
          <!-- </v-btn> -->
          <router-link :to="'/cve/' + encodeURIComponent(search)" class="v-breadcrumbs__item">
            <v-btn class="mt-0" text small outlined>
              View CVE
            </v-btn>
          </router-link>
        </template>
      </v-text-field>
    </div>
    <!-- <dashboard-core-settings /> -->
    <!-- <v-menu bottom left min-width="200" offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" color="white" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-web</v-icon>
        </v-btn>
      </template>
      <v-list :tile="false" flat>
        <v-list-item @click.prevent="changeLang('ro')">
          <v-list-item-title>{{ $t("romanian") }}</v-list-item-title>
        </v-list-item>
        <v-divider class="mb-2 mt-2" />
        <v-list-item @click.prevent="changeLang('en')">
          <v-list-item-title>{{ $t("english") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu bottom left min-width="200" offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" color="white" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list :tile="false" flat>
        <template v-for="(p, i) in profile">
          <v-divider v-if="p.divider" :key="`divider-${i}`" class="mb-2 mt-2" />
          <v-list-item v-else :key="`item-${i}`" dense @click.prevent="doAction(p.action)">
            <v-list-item-icon class="mr-3">
              <v-icon color="primary">{{ p.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="p.title" />
          </v-list-item>
        </template>
      </v-list>
    </v-menu> -->
  </v-app-bar>
</template>
<script>
import { mapState, mapMutations } from "vuex"
export default {
  name: "DashboardCoreAppBar",
  components: {
    // DashboardCoreSettings: () => import("./Settings")
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({ search: "" }),
  computed: {
    ...mapState(["drawer"]),
    profile() {
      if (this.userName) {
        if (this.userId) {
          return [
            { title: this.userName, icon: "mdi-account", action: "account" },
            { divider: true },
            { title: this.$t("Căutări salvate"), icon: "mdi-magnify-plus", action: "searchs" },
            { title: this.$t("Favorite"), icon: "mdi-heart", action: "favorites" },
            { title: this.$t("Circulație"), icon: "mdi-swap-horizontal", action: "flow" },
            { title: this.$t("Setări cont"), icon: "mdi-cog", action: "settings" },
            { divider: true },
            { title: this.$t("Ieșire cont"), icon: "mdi-logout", action: "logout" }
          ]
        } else {
          return [
            { title: this.userName, icon: "mdi-account", action: "account" },
            { divider: true },
            { title: this.$t("Căutări salvate"), icon: "mdi-magnify-plus", action: "searchs" },
            { title: this.$t("Favorite"), icon: "mdi-heart", action: "favorites" },
            { title: this.$t("Setări cont"), icon: "mdi-cog", action: "settings" },
            { divider: true },
            { title: this.$t("Ieșire cont"), icon: "mdi-logout", action: "logout" }
          ]
        }
      }
      return [
        { title: this.$t("Autentificare"), icon: "mdi-account-circle", action: "login" },
        { divider: true },
        { title: this.$t("Înregistrare"), icon: "mdi-account-plus", action: "register" }
      ]
    },
    userName() {
      return this.$store.getters.userName
    },
    userId() {
      return this.$store.getters.userId
    }
  },
  watch: {
    $route(to) {
      //this.$log("route to ", to)
      if (to.name == "CautareGenerala") {
        this.setSearch()
      } else {
        this.search = ""
      }
    }
  },
  created() {
    this.setSearch()
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),
    searchGeneral() {
      this.$router.push({ path: "/cve/" + this.search })
    },
    setSearch() {
      if (this.$route.query) {
        if (this.$route.query.q) {
          this.search = this.$route.query.q
        }
      }
    },
    changeLang(e) {
      //this.$log("change lang ", e)
      this.$i18n.locale = e
      this.$store.commit("language", e)
    },
    doAction(action) {
      this.$log("action2 ", action)
      switch (action) {
        case "profile":
          this.$router.push({ path: "/profile" })
          break
        case "login":
          this.$router.push({ path: "/autentificare" })
          break
        case "register":
          this.$router.push({ path: "/inregistrare" })
          break
        case "flow":
          this.$router.push({ path: "/cont/circulatie" })
          break
        case "favorites":
          this.$router.push({ path: "/cont/favorite" })
          break
        case "settings":
          this.$router.push({ path: "/cont/setari" })
          break
        case "logout":
          this.$store.dispatch("logOutUser")
          this.$router.push({ path: "/autentificare" })
          break
      }
    }
  }
}
</script>
